<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Empleados" :exportInfo="exportItems">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :disabled="actionsPermission" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
        <v-switch v-model="onlyActive" label="Activos" class="ml-3"></v-switch>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <Icon-Btn @onClick="view(item)" label="Editar" icon="mdi-pencil" :disabled="actionsPermission" :loading="loading" top />
          <Icon-Btn @onClick="view(item, 'roles')" label="Actualizar roles" icon="mdi-shield-account" :disabled="actionsPermission" :loading="loading" top />
          <Icon-Btn @onClick="viewReset(item)" label="Actualizar contraseña" icon="mdi-key" :disabled="actionsPermission" :loading="loading" color="warning" top />
          <Icon-Btn v-if="!item.fecha_borrado" @onClick="remove(item.idusuario)" label="Dar de baja" icon="mdi-arrow-down-circle-outline" :disabled="actionsPermission" :loading="loading" color="error" top />
          <Icon-Btn v-else @onClick="reactivate(item.idusuario)" label="Reactivar" icon="mdi-account-reactivate" :disabled="actionsPermission" :loading="loading" color="success" top />
        </template>
        <template v-slot:[`item.detalles_usuarios[0].tipo_horario`]="{ item }">
          <div v-if="item.detalles_usuarios.length > 0">
            <v-edit-dialog v-if="item.detalles_usuarios[0].tipo_horario.detalles_tipos_horarios.length > 0">
              {{ item.detalles_usuarios[0].tipo_horario.nombre }}
              <v-tooltip top
                ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading">mdi-calendar-clock-outline</v-icon></template
                ><span>Ver horarios de {{ item.detalles_usuarios[0].tipo_horario.nombre }} </span></v-tooltip
              >
              <template v-slot:input>
                <div class="text-center">
                  <h3 class="title text-center" v-text="item.detalles_usuarios[0].tipo_horario.nombre" />
                </div>
                <Schedule-Table :scheduleData="item.detalles_usuarios[0].tipo_horario.detalles_tipos_horarios" :key="forms.new.tipo_horario" />
              </template>
            </v-edit-dialog>
            <span v-else> Horarios no disponibles </span>
          </div>
        </template>
        <template v-slot:[`item.roles[0].rol.rol`]="{ item }">
          <v-chip-group column multiple v-if="item.roles.length > 0">
            <v-chip v-text="item.roles[0].rol.rol" color="primary" small />
            <v-edit-dialog v-if="item.roles.length > 1">
              <v-chip v-text="item.roles.length - 1 + '+'" small />
              <template v-slot:input>
                <v-chip-group column multiple>
                  <v-chip v-text="roles.rol.rol" v-for="(roles, n) in item.roles" :key="n" color="primary" small />
                </v-chip-group>
              </template>
            </v-edit-dialog>
          </v-chip-group>
          <v-chip v-text="'Sin rol'" v-else small />
        </template>
        <template v-slot:[`item.detalles_usuarios[0].no_empleado`]="{ item }">
          {{ item.detalles_usuarios[0].no_empleado ? item.detalles_usuarios[0].no_empleado : "Pendiente de ingresar" }}
        </template>
        <template v-slot:[`item.fecha_borrado`]="{ item }">
          <v-chip :color="item.fecha_borrado ? 'error' : 'success'" small outlined>
            {{ item.fecha_borrado ? "Inactivo" : "Activo" }}
          </v-chip>
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nuevo usuario">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.new.nombre" label="Nombre" prepend-icon="mdi-account" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.apellido_paterno" label="Apellido Paterno" prepend-icon="mdi-alpha-p" :rules="rules.apellido_paterno" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.apellido_materno" label="Apellido Materno" prepend-icon="mdi-alpha-m" :rules="rules.apellido_materno" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field type="number" v-model.number="forms.new.no_empleado" label="Numero de empleado" prepend-icon="mdi-badge-account-outline" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.telefono" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.telefono" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.new.email" label="Correo" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.new.password" label="Contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.new.departamento" :items="departments" item-text="nombre" item-value="iddepartamento" label="Departamento" prepend-icon="mdi-sitemap" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.new.puesto" :items="positions" item-text="nombre" item-value="idpuesto" label="Puesto" prepend-icon="mdi-briefcase-outline" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <Date-Picker v-model="forms.new.fecha_ingreso" label="Fecha de ingreso" />
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.new.tipo_horario" :items="schedulesTypes" item-text="nombre" item-value="idtipo_horario" label="Tipo de horario" prepend-icon="mdi-calendar-blank-multiple" persistent-hint no-data-text="No se encontraron resultados" dense outlined>
              <template slot="append-outer">
                <div class="append-btn">
                  <v-btn color="primary" @click="viewSchedule = !viewSchedule" :disabled="forms.new.tipo_horario.length == 0" small fab>
                    <v-icon v-if="!viewSchedule">mdi-eye</v-icon>
                    <v-icon v-if="viewSchedule">mdi-eye-off</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="viewSchedule">
            <Schedule-Table :scheduleData="searchSchedule(forms.new.tipo_horario)" :key="forms.new.tipo_horario" />
          </v-col>
          <v-col cols="12">
            <Date-Picker v-model="forms.new.fecha_nacimiento" label="Fecha de nacimiento" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.talla_playera" label="Talla de playera" prepend-icon="mdi-tshirt-crew" :rules="rules.max3" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.cuenta_bancaria" label="Cuenta bancaria" prepend-icon="mdi-smart-card-outline" :rules="rules.max13" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.clabe" label="Clabe" prepend-icon="mdi-credit-card-outline" :rules="rules.max18" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.new.banco" label="Banco" prepend-icon="mdi-bank" :rules="rules.max50" :disabled="loading" dense outlined></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar usuario">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading"/>
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-account" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.update.apellido_paterno" label="Apellido Paterno" prepend-icon="mdi-alpha-p" :rules="rules.apellido_paterno" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6"> <v-text-field v-model="forms.update.apellido_materno" label="Apellido Materno" prepend-icon="mdi-alpha-m" :rules="rules.apellido_materno" :disabled="loading" dense outlined></v-text-field> </v-col
          ><v-col cols="6">
            <v-text-field type="number" v-model.number="forms.update.no_empleado" label="Numero de empleado" prepend-icon="mdi-badge-account-outline" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.update.telefono" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.telefono" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.update.email" label="Correo" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.update.departamento" :items="departments" item-text="nombre" item-value="iddepartamento" label="Departamento" prepend-icon="mdi-sitemap" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.update.puesto" :items="positions" item-text="nombre" item-value="idpuesto" label="Puesto" prepend-icon="mdi-briefcase-outline" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <Date-Picker v-model="forms.update.fecha_ingreso" label="Fecha de ingreso" />
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.update.tipo_horario" :items="schedulesTypes" item-text="nombre" item-value="idtipo_horario" label="Tipo de horario" prepend-icon="mdi-calendar-blank-multiple" persistent-hint no-data-text="No se encontraron resultados" dense outlined>
              <template slot="append-outer">
                <div class="append-btn">
                  <v-btn color="primary" @click="viewSchedule = !viewSchedule" :disabled="forms.update.tipo_horario.length == 0" small fab>
                    <v-icon v-if="!viewSchedule">mdi-eye</v-icon>
                    <v-icon v-if="viewSchedule">mdi-eye-off</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="viewSchedule">
            <Schedule-Table :scheduleData="searchSchedule(forms.update.tipo_horario)" :key="forms.update.tipo_horario" />
          </v-col>
          <v-col cols="6">
            <Date-Picker v-model="forms.update.fecha_nacimiento" label="Fecha de nacimiento" />
          </v-col>
          <v-col cols="6">
            <Date-Picker v-model="forms.update.fecha_baja" label="Fecha de baja" disabled />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.update.talla_playera" label="Talla de playera" prepend-icon="mdi-tshirt-crew" :rules="rules.max3" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.update.cuenta_bancaria" label="Cuenta bancaria" prepend-icon="mdi-smart-card-outline" :rules="rules.max13" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.update.clabe" label="Clabe" prepend-icon="mdi-credit-card-outline" :rules="rules.max18" :disabled="loading" dense outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="forms.update.banco" label="Banco" prepend-icon="mdi-bank" :rules="rules.max50" :disabled="loading" dense outlined></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.roles" title="Actualizar roles">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="updateRol()" :loading="loading" :disabled="forms.update.fecha_borrado"/>
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-account" disabled dense outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete v-model="forms.update.roles" :items="catalogues.roles" item-text="rol" item-value="idrol" label="Rol" prepend-icon="mdi-shield-account" multiple chips small-chips persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.reset" title="Actualizar contraseña">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="resetPassword()" :loading="loading" />
      </template>
      <v-form ref="formReset" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.reset.email" label="Correo" prepend-icon="mdi-email" disabled required dense outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.reset.password" label="Contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" required dense outlined></v-text-field>
            <v-text-field v-model="forms.reset.confirmPassword" label="Confirmar contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" required dense outlined></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el usuario?</v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <Date-Picker v-model="forms.remove.fecha_baja" label="Fecha de baja" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small :disabled="loading" color="disabled" elevation="0" style="margin: 5px" @click="cancel()">
            Cancelar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn small :loading="loading" color="error" elevation="0" style="margin: 5px" @click="remove()">
            Eliminar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.reactivate" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea reactivar el usuario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small :disabled="loading" color="disabled" elevation="0" style="margin: 5px" @click="cancel()">
            Cancelar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn small :loading="loading" color="success" elevation="0" style="margin: 5px" @click="reactivate()">
            Aceptar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, DatePicker, ScheduleTable, IconBtn } from "@/components";
import moment from 'moment-timezone'

export default {
  name: "user",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    DatePicker,
    ScheduleTable,
    IconBtn,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idusuario" },
      { text: "Nombre", align: "left", sortable: true, value: "detalles_usuarios[0].nombre" },
      { text: "Apellido Paterno", align: "left", sortable: true, value: "detalles_usuarios[0].apellido_paterno" },
      { text: "Apellido Materno", align: "left", sortable: true, value: "detalles_usuarios[0].apellido_materno" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Numero de empleado", align: "left", sortable: true, value: "detalles_usuarios[0].no_empleado" },
      { text: "Telefono", align: "left", sortable: true, value: "detalles_usuarios[0].telefono" },
      { text: "Puesto", align: "left", sortable: true, value: "detalles_usuarios[0].puesto.nombre" },
      { text: "Departamento", align: "left", sortable: true, value: "detalles_usuarios[0].departamento.nombre" },
      { text: "Horario", align: "left", sortable: true, value: "detalles_usuarios[0].tipo_horario" },
      { text: "Activo", align: "left", sortable: true, value: "fecha_borrado" },
      { text: "Roles", align: "left", sortable: true, value: "roles[0].rol.rol" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    currItems: [],
    itemsPerPage: 20,
    schedulesTypes: [],
    departments: [],
    positions: [],
    selected: [],
    deleted: null,
    reactivated: null,
    show: false,
    viewSchedule: false,
    catalogues: {
      roles: [],
    },
    onlyActive: false,
    forms: {
      new: {
        email: "",
        password: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        telefono: "",
        puesto: "",
        departamento: "",
        tipo_horario: "",
        fecha_ingreso: "",
        no_empleado: 0,
        banco: "",
        clabe: "",
        cuenta_bancaria: "",
        fecha_nacimiento: "",
        talla_playera: "",
      },
      update: {
        idusuario: "",
        email: "",
        password: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        telefono: "",
        puesto: "",
        departamento: "",
        tipo_horario: "",
        fecha_ingreso: "",
        no_empleado: 0,
        roles: [],
        banco: "",
        clabe: "",
        cuenta_bancaria: "",
        fecha_nacimiento: "",
        fecha_baja: "",
        talla_playera: "",
      },
      reset: {
        idusuario: "",
        password: "",
        confirmPassword: "",
      },
      remove: {
        fecha_baja: null
      }
    },
    idUpdate: 0,
    rules: {
      email: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 320) || "El campo excede la longitud máxima", (v) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "El campo es inválido"],
      password: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length >= 8) || "La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número",
        (v) => (v && v.length <= 60) || "La contraseña excede la longitud máxima",
        (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || "La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número",
      ],
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      apellido_paterno: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      apellido_materno: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      telefono: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 15) || "El campo excede la longitud máxima"],
      addresValid: [(v) => !!v || "El campo es requerido"],
      addres20: [(v) => (v && v.length <= 20) || "El campo excede la longitud máxima"],
      addres50: [(v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
      addres100: [(v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      max13: [(v) => (v && v.length <= 13) || "El campo excede la longitud máxima"],
      max18: [(v) => (v && v.length <= 18) || "El campo excede la longitud máxima"],
      max50: [(v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
      max3: [(v) => (v && v.length <= 3) || "El campo excede la longitud máxima"],      
    },
    dialogs: {
      new: false,
      update: false,
      companies: false,
      roles: false,
      reset: false,
      address: false,
      remove: false,
      reactivate: false
    },
  }),
  computed: {
    exportItems() {
      let array = [];
      let auxItems = [];
      if (this.itemsPerPage == -1) {
        auxItems = this.currItems
      }
      else {
        auxItems = this.items
      }
      for (let data of auxItems) {
        array.push({
          id: data.idusuario ? data.idusuario : '',
          nombre: data.detalles_usuarios[0].nombre ? data.detalles_usuarios[0].nombre : '',
          apellido_paterno: data.detalles_usuarios[0].apellido_paterno ? data.detalles_usuarios[0].apellido_paterno : '',
          apellido_materno: data.detalles_usuarios[0].apellido_materno ? data.detalles_usuarios[0].apellido_materno : '',
          nombre_completo: `${data.detalles_usuarios[0].nombre} ${data.detalles_usuarios[0].apellido_paterno} ${data.detalles_usuarios[0].apellido_materno}`,
          no_empleado: data.detalles_usuarios[0].no_empleado ? data.detalles_usuarios[0].no_empleado : '',
          telefono: data.detalles_usuarios[0].telefono ? data.detalles_usuarios[0].telefono : '',
          puesto: data.detalles_usuarios[0].puesto.nombre ? data.detalles_usuarios[0].puesto.nombre : '',
          departamento: data.detalles_usuarios[0].departamento.nombre ? data.detalles_usuarios[0].departamento.nombre : '',
          tipo_horario: data.detalles_usuarios[0].tipo_horario.nombre ? data.detalles_usuarios[0].tipo_horario.nombre : '',
          activo: data.fecha_borrado ? 'Inactivo' : 'Activo',
        })
      }
      return array
    },
    actionsPermission(){
      let allAccess = false
      let value = false;
      for (let rol of this.$session.get("roles")) {
        if (rol =="sistemas") {
          value = true;
        }
        if(rol =="administrador"){
          allAccess = true
        }
      }
      return allAccess ? false : value;
    },
    },
  mounted() {
    this.refresh();
  },
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    },
    onlyActive(val) {
      this.refresh()
    }
  },
  methods: {
    async refresh() {
      this.loading = true;
      const role = await this.axios.get(services.routes.role);
      if (role.data.data) {
        this.catalogues.roles = role.data.data;
      }
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.items = response.data.data;
          this.forms.new.no_empleado = 0
          for (let data of this.items) {
            if(data.detalles_usuarios[0].no_empleado + 1 > this.forms.new.no_empleado){
              this.forms.new.no_empleado = data.detalles_usuarios[0].no_empleado + 1
            }
          }
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nombre_completo =
            this.items[i].detalles_usuarios[0].nombre + " " +
            this.items[i].detalles_usuarios[0].apellido_paterno + " " +
            this.items[i].detalles_usuarios[0].apellido_materno
          }
          if (this.onlyActive) {
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].fecha_borrado) {
                this.items.splice(i, 1);
                i--;
              }
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
          this.getPosition();
          this.getSchedule();
          this.getDepartament();
        });
    },
    async getPosition() {
      this.loading = true;
      this.axios
        .get(services.routes.position)
        .then((response) => {
          this.positions = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async getSchedule() {
      this.loading = true;
      this.axios
        .get(services.routes.scheduleType)
        .then((response) => {
          this.schedulesTypes = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async getDepartament() {
      this.loading = true;
      this.axios
        .get(services.routes.department)
        .then((response) => {
          this.departments = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        for (let key in this.forms.new) {
          if(key == 'no_empleado'){
            this.forms.new['no_empleado'] = this.forms.new['no_empleado'];
          }else{
          this.forms.new[key] = "";
          }
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update || this.dialogs.companies || this.dialogs.roles) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key] = "";
        }
        this.dialogs.update = false;
        this.dialogs.companies = false;
        this.dialogs.roles = false;
      } else if (this.dialogs.reset) {
        this.$refs.formReset.reset();
        for (let key in this.forms.reset) {
          this.forms.reset[key] = "";
        }
        this.dialogs.reset = false;
      } else if (this.dialogs.address) {
        for (let key in this.forms.address) {
          if (key == "cp") {
            this.forms.address[key] = 0;
          } else {
            this.forms.address[key] = "";
          }
        }
        this.refresh();
        this.viewSchedule = false;
        this.dialogs.address = false;
      } else if (this.dialogs.remove) {
        this.deleted = null
        this.forms.remove.fecha_baja = null
        this.dialogs.remove = false;
      } else if (this.dialogs.reactivate) {
        this.reactivated = null
        this.dialogs.reactivate = false;
      }
      this.show = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        let dataAux = JSON.parse(JSON.stringify(this.forms.new));
        data.detalles_usuarios = [{}]
        for (let key in dataAux) {
          if (key !== 'idusuario' && key !== 'email' && key !== 'password') {
            data.detalles_usuarios[0][key] = dataAux[key]
            delete data[key]
          }
        }
        this.axios
          .post(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data, type) {
      if (type === "roles") {
        this.dialogs.roles = true;
        try {
          this.forms.update.usuario = data.idusuario;
          this.forms.update.nombre = data.detalles_usuarios.length > 0 ? data.detalles_usuarios[0].nombre + " " + data.detalles_usuarios[0].apellido_paterno + " " + data.detalles_usuarios[0].apellido_materno : "";
          this.forms.update.rol = [];
          if (data.usuarios_roles.length) {
            this.forms.update.roles = [];
            for (let i of data.usuarios_roles) {
              this.forms.update.roles.push(i.rol.idrol);
            }
          }
          this.dialogs.roles = true;
        } catch (error) {
        }
      } else {
        this.forms.update = {};
        this.forms.update = {
          idusuario: data.idusuario,
          email: data.email,
          nombre: data.detalles_usuarios[0].nombre,
          apellido_paterno: data.detalles_usuarios[0].apellido_paterno,
          apellido_materno: data.detalles_usuarios[0].apellido_materno,
          no_empleado: data.detalles_usuarios[0].no_empleado,
          telefono: data.detalles_usuarios[0].telefono,
          departamento: data.detalles_usuarios[0].departamento.iddepartamento,
          puesto: data.detalles_usuarios[0].puesto.idpuesto,
          tipo_horario: data.detalles_usuarios[0].tipo_horario.idtipo_horario,
          fecha_ingreso: data.detalles_usuarios[0].fecha_ingreso,
          banco: data.detalles_usuarios[0].banco,
          clabe: data.detalles_usuarios[0].clabe,
          cuenta_bancaria: data.detalles_usuarios[0].cuenta_bancaria,
          fecha_nacimiento: data.detalles_usuarios[0].fecha_nacimiento,
          fecha_baja: data.detalles_usuarios[0].fecha_baja,
          talla_playera: data.detalles_usuarios[0].talla_playera,
        };
        this.dialogs.update = true;
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        let dataAux = JSON.parse(JSON.stringify(this.forms.update));
        data.detalles_usuarios = [{}]
        for (let key in dataAux) {
          if (key !== 'idusuario' && key !== 'email' && key !== 'password') {
            data.detalles_usuarios[0][key] = dataAux[key]
            delete data[key]
          }
        }
        this.axios
          .put(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    updateRol() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        this.forms.update.roles = [...this.forms.update.roles];
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.user + "/role", { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    viewReset(data) {
      this.forms.reset = {
        idusuario: data.idusuario,
        email: data.email,
        password: "",
      };
      this.dialogs.reset = true;
    },
    resetPassword() {
      if (this.$refs.formReset.validate()) {
        if (this.forms.reset.password === this.forms.reset.confirmPassword) {
          this.loading = true;
          let data = JSON.parse(JSON.stringify(this.forms.reset));
          delete data.confirmPassword;
          this.axios
            .put(services.routes.user + "/password", { data })
            .then((response) => {
              this.cancel();
              this.refresh();
              this.alert.active = true;
              this.alert.color = "success";
              this.alert.msg = "Contraseña actualizada exitosamente";
            })
            .catch((error) => {
              this.alert.active = true;
              this.alert.color = "error";
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Las contraseñas no coinciden";
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let fecha = this.forms.remove.fecha_baja
        this.axios
          .delete(services.routes.user, { data: { data: { idusuario: this.deleted, fecha_baja: fecha } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    reactivate(id) {
      if (!id) {
        this.loading = true;
        let fecha = moment().format('YYYY-MM-DD')
        let data = {
          idusuario: this.reactivated,
          fecha_ingreso: fecha
        }
        this.axios
          .put(services.routes.user + '/reactivate', { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario reactivado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.reactivated = id;
        this.dialogs.reactivate = true;
      }
    },
    validColor(n) {
      let color = "";
      let name = n.toLowerCase();
      if (name === "administrador") {
        color = "error";
      } else if (name === "coordinador") {
        color = "primary";
      } else if (name === "supervisor") {
        color = "secondary";
      } else if (name === "encargado") {
        color = "success";
      } else if (name === "publico") {
        color = "teal darken-3";
      }
      return color;
    },
    searchSchedule(id) {
      for (let schedule of this.schedulesTypes) {
        if (schedule.idtipo_horario == id) {
          return schedule.detalles_tipos_horarios;
        }
      }
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
  .append-btn {
    position: relative;
    bottom: 10px;
  }
</style>
